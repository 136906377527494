<i18n>
[
    "login__emailVerify--title",
    "account__email--label",
    "global__submit",
]
</i18n>

<template>
    <div class="c-email-login">
        <LoginContainer
            :title="$t('login__emailVerify--title')"
            :onSubmit="onSubmit"
            :errorText="serviceErrorMessage"
            :submitButton="{
                class: 'u-spacer--3',
                text: $t('global__submit'),
            }"
        >
            <template #form-field>
                <EmailInput
                    v-model="email"
                    :shouldAutoFocus="true"
                    :labelText="$t('account__email--label')"
                    name="email"
                    class="u-spacer--5"
                />
            </template>
        </LoginContainer>
    </div>
</template>

<script>

import { get } from 'lodash-es';
import { mapActions, mapGetters, mapState } from 'vuex';

import { UNIVERSAL_REDIRECT } from '~coreModules/core/js/store';
import { genericRouteNames } from '~coreModules/core/js/router-constants';

import { LOGIN_MODULE_NAME, SUBMIT_EMAIL_LOGIN } from '~coreRoutes/login/js/login-store';

import LoginContainer from '~coreRoutes/login/components/LoginContainer.vue';
import EmailInput from '~coreModules/core/components/ui/form-elements/text-inputs/EmailInput.vue';

export default {
    name: 'LoginEmailVerify',
    components: {
        LoginContainer,
        EmailInput,
    },
    data() {
        return {
            email: '',
            serviceErrorMessage: '',
        };
    },
    computed: {
        ...mapGetters([
            'appBaseUrl',
        ]),
        ...mapState([
            'loginRedirectUrl',
        ]),
    },
    fetchData({ route, router, store }) {
        const { token } = route.query;
        if (!token) {
            return store.dispatch(UNIVERSAL_REDIRECT, {
                router,
                route: { name: genericRouteNames.login.email.base },
            });
        }
        return Promise.resolve();
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            submitEmailLogin: SUBMIT_EMAIL_LOGIN,
        }),
        onSubmit() {
            const { token } = this.$route.query;
            return this.submitEmailLogin({
                email: this.email,
                verificationCode: token,
            })
                .then(() => {
                    window.location = this.loginRedirectUrl || this.appBaseUrl;
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', '');
                });
        },
    },
};
</script>

<style lang="scss">

</style>
